import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner2"
import LocalizedLink from "../components/utils/LocalizedLink"

export const query = graphql`
  query Project($id: Int) {
    allProjectsJson: strapiProjeto(strapi_id: { eq: $id }) {
      bannerTop {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 294) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      locale
      degree
      status
      title
      owner
      slug
      excerpt
      Reviewers {
        Name
        position
      }
      description {
        data {
          description
        }
      }
    }
  }
`

export const Projeto = ({ pageContext: { id, locale, currentPath }, data }) => {
  const project = data.allProjectsJson || []

  return (
    <Layout locale={locale} currentPath={currentPath}>
      {project.bannerTop && (
        <Banner
          banner={project.bannerTop.localFile}
          text=""
          layer={false}
          opacityValue={1}
          //logo={obcrei}
        />
      )}

      <div className="container">
        <div className="project-wrapper">
          <div className="row">
            <div className="col-sm-1 d-flex  align-items-center" />
            <div
              className=" col-sm-10 col-xs-12 text-legend color-primary"
              style={{ marginTop: 100, marginBottom: 22 }}
              dangerouslySetInnerHTML={{ __html: project.status }}
            />
          </div>

          <div className="row justify-content-md-center">
            <div className="col-sm-10 col-xs-12">
              <h2
                className="mt--22 mb--22"
                dangerouslySetInnerHTML={{ __html: project.title }}
              />
            </div>

            <div
              className="col-sm-10 col-xs-12 text-default bold mb--22"
              dangerouslySetInnerHTML={{ __html: project.owner }}
            />

            <div className="col-sm-10 col-xs-12 text-default">
              {project.Reviewers &&
                project.Reviewers.map((reviewer, i) => (
                  <p className="mb-1" key={i}>
                    <span className="bold">{reviewer.position}: </span>
                    {reviewer.Name}
                  </p>
                ))}
            </div>

            <div
              className="col-sm-10 col-xs-12 mt--22 color-primary text-default"
              style={{ lineHeight: "42px" }}
              dangerouslySetInnerHTML={{ __html: project.excerpt }}
            />
            <div className="col-sm-10 d-flex  align-items-center">
              <div
                className="divider"
                style={{ marginTop: 58, marginBottom: 65 }}
              />
            </div>
            <div
              className="col-sm-10 col-xs-12 mt--22  color-primary text-default"
              style={{ lineHeight: "42px" }}
              dangerouslySetInnerHTML={{
                __html: project.description?.data?.description,
              }}
            />

            <div
              className="col-sm-10 col-xs-12"
              style={{ marginTop: 45, marginBottom: 120 }}>
              {project.locale && (
                <LocalizedLink
                  locale={project.locale}
                  to="/projetos"
                  className="link--red bold">
                  {project.locale === "pt"
                    ? "Voltar para Projetos"
                    : "Return to Projects"}
                </LocalizedLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query($path: String!) {
//     allProjectsJson(filter: { path: { eq: $path } }) {
//       edges {
//         node {
//           status
//           bannerImage {
//             childImageSharp {
//               fluid(maxWidth: 1200, maxHeight: 294) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//           language
//           title
//           owner
//           path
//           reviewers {
//             position
//             name
//           }
//           excerpt
//           description
//         }
//       }
//     }
//   }
// `

export default Projeto
